import React, { useEffect, useRef, useState } from 'react';
import { UnstyledLink, 
    Page, 
    PageHeader, 
    Logo, 
    Section, 
    SectionHeader, 
    SectionTitleHeader, 
    SectionSubtitle, 
    PhotoWithText, 
    PhotoWithTextImage, 
    PhotoWithTextContent, 
    InformationsList, 
    InformationsListElement, 
    PageFooter,
    TextArea,
    FooterItem,
    FooterSplit
} from "./style";
import './global-style.css';
import logo from '../../images/logo.png';

const OfferPrintPage = ({offer}) => {
    // Code copied from src\components\OfferPage\FlatDescription\DetailsComponent\House\index.jsx
    // Information list is also copied.
    const address = `${
        offer.baseData.address.city ? offer.baseData.address.city + "," : ""
      } ${
        offer.baseData.address.district ? offer.baseData.address.district + "," : ""
      } ${offer.baseData.address.state ? offer.baseData.address.state : ""}`
    
      const accessRoadSwitch = accessRoadType => {
        switch (accessRoadType) {
          case "asphalt":
            return "droga asfaltowa"
    
          case "hardened":
            return "droga utwardzana"
    
          case "field":
            return "droga polna"
    
          case "lack":
            return "brak"
    
          default:
            return "nie podano"
        }
      }
    
      const propertyTypeSwitch = propertyType => {
        switch (propertyType) {
          case "flat":
            return "mieszkanie"
    
          case "house":
            return "dom"
    
          case "land":
            return "działka"
    
          case "office_space":
            return "przestrzeń biurowa"
    
          case "usable_locale":
            return "lokal użytkowy"
    
          default:
            return "nie podano"
        }
      }
    
      const landTypeSwitch = landType => {
        switch (landType) {
          case "construction":
            return "budowlana"
    
          case "agricultural":
            return "rolna"
    
          case "investment":
            return "inwestycyjna"
    
          case "services":
            return "usługowa"
    
          case "recreational":
            return "rekreacyjna"
    
          case "forest":
            return "leśna"
    
          case "habitat":
            return "siedliskowa"
    
          case "farm":
            return "gospodarstwo"
    
          case "craft":
            return "rzemieślnicza"
    
          case "agro_construction":
            return "rolno-budowlana"
    
          default:
            return "nie podano"
        }
      }
    
      const fenceTypeSwitch = fenceType => {
        switch (fenceType) {
          case "metal":
            return "metalowe"
    
          case "mesh":
            return "siatka"
    
          case "brick":
            return "murowane"
    
          case "concrete":
            return "betonowe"
    
          case "wooden":
            return "drewniane"
    
          case "hedge":
            return "żywopłot"
    
          case "mixed":
            return "mieszane"
    
          case "none":
            return "brak"
    
          default:
            return "nie podano"
        }
      }
    
      const houseTypeSwitch = houseType => {
        switch (houseType) {
          case "detached":
            return "wolnostojący"
    
          case "middle_segment":
            return "segment środkowy"
    
          case "extreme_segment":
            return "segment skrajny"
    
          case "twin":
            return "bliźniak"
    
          case "half_twin":
            return "pół bliźniaka"
    
          case "tenement":
            return "kamienica"
    
          case "villa":
            return "willa"
    
          case "residence":
            return "rezydencja"
    
          case "manor_house":
            return "dworek"
    
          case "serial":
            return "szeregowy"
    
          case "house_floor":
            return "piętro domu"
    
          case "recreational":
            return "rekreacyjny"
    
          default:
            return "nie podano"
        }
      }
    
      const bathroomConditionSwitch = bathroomCondition => {
        switch (bathroomCondition) {
          case "good":
            return "dobra"
    
          case "to_be_renewed":
            return "do odnowienia"
    
          case "for_renovation":
            return "działka"
    
          case "high_standard":
            return "do remontu"
    
          default:
            return "nie podano"
        }
      }
    
      const buildingConditionSwitch = buildingCondition => {
        switch (buildingCondition) {
          case "luxury":
            return "luksusowy"
    
          case "very_good":
            return "bardzo dobry"
    
          case "good":
            return "dobry"
    
          case "for_renovation":
            return "do remontu"
    
          case "after_renovation":
            return " po remoncie"
    
          case "for_finishing":
            return "do wykończenia"
    
          case "renovated":
            return "odnowiony"
    
          case "to_be_renewed":
            return "do odnowienia"
    
          case "open_shell":
            return "stan surowy otwarty"
    
          case "closed_shell":
            return "stan surowy zamknięty"
    
          case "without_white_assembly":
            return "bez białego montażu"
    
          case "in_construction":
            return "w budowie"
    
          case "for_living":
            return "do zamieszkania"
    
          case "high_standard":
            return "wysoki standard"
    
          case "with_tenants":
            return "z lokatorami"
    
          default:
            return "nie podano"
        }
      }
    
      const garageSwitch = garage => {
        switch (garage) {
          case "detached":
            return "wolnostojący"
    
          case "in_the_building":
            return "w budynku"
    
          case "not_available":
            return "brak"
    
          default:
            return "nie podano"
        }
      }
    
      const heatingSwitch = heatingType => {
        switch (heatingType) {
          case "co_carbon":
            return "CO węglowe"
    
          case "co_gas":
            return "CO gazowe"
    
          case "co_electric":
            return "CO elektryczne"
    
          case "co_municipal":
            return "CO miejskie"
    
          case "oil":
            return "olejowe"
    
          case "lack":
            return "brak"
    
          case "coal_stove":
            return "piec węglowy"
    
          case "storefront":
            return "etażowe"
    
          case "furnance":
            return "piec"
    
          case "fireplace":
            return " kominek"
    
          default:
            return "nie podano"
        }
      }

    const year = new Date().getFullYear();

    const descriptionRef = useRef(null);
    const [descriptions, setDescriptions] = useState([offer.baseData.description]);

    useEffect(() => {
      if(!descriptionRef.current)
        return;

      const el = descriptionRef.current;
      const pageEl = el.parentElement.parentElement;
      const pageStyle = getComputedStyle(pageEl);
      const pagePadding = +pageStyle.paddingTop.slice(0, -2) + +pageStyle.paddingBottom.slice(0, -2);

      if(pageEl.offsetTop + pageEl.offsetHeight - pagePadding >= el.offsetTop + el.offsetHeight)
      {
        // Text successfully trimmed.
        return;
      }

      setDescriptions((v) => {
        v[0] = v[0].replace(/[\s]+.*?$/, (word) => {
          if(v.length === 1)
            v[1] = '';

          v[1] = word+v[1];

          return '';
        });

        return [...v];
      });

    }, [descriptionRef, descriptions]);

    return <>
    <Page>
        <PageHeader>
          <Logo src={logo} />
        </PageHeader>

        <Section>
            <PhotoWithText>
                <PhotoWithTextImage src={offer.baseData.meta.ogPictureUrl} />
                <PhotoWithTextContent>
                    Zdjęcie: Copyright &copy; {year} Agencja Nieruchomości, Biuro Nieruchomości - DĘBOSZ Nieruchomości
                </PhotoWithTextContent>
          </PhotoWithText>
        </Section>

        <Section>
          <SectionTitleHeader>
              <b>{offer.baseData.meta.title}</b>
              {address && ` - ${address}`}
            </SectionTitleHeader>
          <SectionSubtitle>{Intl.NumberFormat("pl").format(offer.baseData.price)} PLN</SectionSubtitle>
        </Section>

        <Section>
          <SectionHeader>Opis:</SectionHeader>
          <TextArea ref={descriptionRef}>{descriptions[0].trim()}</TextArea>
        </Section>
    </Page>

    <Page>
        {descriptions[1] && descriptions[1].trim() != '' && <TextArea bottomMargin>{descriptions[1].trim()}</TextArea>}

        <Section>
          <SectionHeader>Agent:</SectionHeader>
          {offer.baseData.agent.name}<br />
          tel. {offer.baseData.agent.phone}<br />
          {offer.baseData.agent.email}
        </Section>

        <Section>
          <SectionHeader>Informacje:</SectionHeader>
          <InformationsList>
            {offer.baseData?.propertyType && (
                <InformationsListElement>
                typ nieruchomości:<br/>
                {propertyTypeSwitch(offer.baseData.propertyType)}
                </InformationsListElement>
            )}

            {offer.landData?.accessRoad && (
                <InformationsListElement>
                droga dojazdowa:<br/>
                {accessRoadSwitch(offer.landData.accessRoad)}
                </InformationsListElement>
            )}

            {offer.baseData?.addDate && (
                <InformationsListElement>
                data dodania ogłoszenia:<br/>
                {offer.baseData.addDate}
                </InformationsListElement>
            )}

            {offer.landData?.floorage && (
                <InformationsListElement>
                powierzchnia działki w m2:<br/>
                {offer.landData.floorage}
                </InformationsListElement>
            )}

            {(offer.landData?.water !== "lack") |
                offer.landData?.gas |
                offer.landData?.threePhase |
                (offer.landData?.sewerage !== "lack") && (
                <InformationsListElement>
                media:<br/>
                
                    {offer.landData?.water === "lack" ? "" : "woda,"}
                    {offer.landData?.threePhase && " siła,"}
                    {offer.landData?.sewerage === "lack" ? "" : " kanalizacja,"}
                    {offer.landData?.gas && " gaz"}
                
                </InformationsListElement>
            )}

            {/* house */}

            {offer.buildingInfo?.yearOfBuild && (
                <InformationsListElement>
                rok budowy:<br/>
                {offer.buildingInfo.yearOfBuild}
                </InformationsListElement>
            )}

            {offer.buildingInfo?.houseType && (
                <InformationsListElement>
                typ budynku:<br/>
                {houseTypeSwitch(offer.buildingInfo.houseType)}
                </InformationsListElement>
            )}

            {offer.buildingInfo?.roomsCount && (
                <InformationsListElement>
                liczba pokoi:<br/>
                {offer.buildingInfo.roomsCount}
                </InformationsListElement>
            )}

            {offer.buildingInfo?.floorage && (
                <InformationsListElement>
                powierzchnia domu w m2:<br/>
                {offer.buildingInfo.floorage}
                </InformationsListElement>
            )}

            {offer.buildingInfo?.buildingCondition && (
                <InformationsListElement>
                stan budynku:<br/>
                
                    {buildingConditionSwitch(offer.buildingInfo.buildingCondition)}
                
                </InformationsListElement>
            )}

            {offer.buildingInfo?.bathroomCount && (
                <InformationsListElement>
                liczba łazienek:<br/>
                {offer.buildingInfo.bathroomCount}
                </InformationsListElement>
            )}

            {offer.buildingInfo?.bathroomCondition && (
                <InformationsListElement>
                stan łazienek:<br/>
                
                    {bathroomConditionSwitch(offer.buildingInfo.bathroomCondition)}
                
                </InformationsListElement>
            )}

            {offer.buildingInfo?.garage && (
                <InformationsListElement>
                garaż:<br/>
                {garageSwitch(offer.buildingInfo.garage)}
                </InformationsListElement>
            )}

            {offer.buildingInfo?.floorCount && (
                <InformationsListElement>
                liczba pięter:<br/>
                {offer.buildingInfo.floorCount}
                </InformationsListElement>
            )}

            {offer.buildingInfo?.heating && (
                <InformationsListElement>
                typ ogrzewania:<br/>
                {heatingSwitch(offer.buildingInfo.heating)}
                </InformationsListElement>
            )}

            {/* land */}

            {offer.landData?.landType && (
                <InformationsListElement>
                rodzaj działki:<br/>
                {landTypeSwitch(offer.landData.landType)}
                </InformationsListElement>
            )}

            {offer.landData?.fence && (
                <InformationsListElement>
                ogrodzenie:<br/>
                {fenceTypeSwitch(offer.landData.fence)}
                </InformationsListElement>
            )}

            {offer.landData?.length && (
                <InformationsListElement>
                długość działki:<br/>
                {`${offer.landData.length} m`}
                </InformationsListElement>
            )}

            {offer.landData?.width && (
                <InformationsListElement>
                szerokość działki:<br/>
                {`${offer.landData.width} m`}
                </InformationsListElement>
            )}
          </InformationsList>
        </Section>

        {address && <Section>
          <SectionHeader>Lokalizacja:</SectionHeader>
          {address}
        </Section>}

        <PageFooter>
          <FooterItem>DĘBOSZ Nieruchomości</FooterItem>
          <FooterSplit />
          <FooterItem>www.debosz.com.pl</FooterItem>
          <FooterSplit />
          <FooterItem>Rynek 12/3, Niepołomice</FooterItem>
        </PageFooter>
        </Page>
    </>;
};

export default OfferPrintPage;