import styled from "styled-components"

const designToPageScale = 1170 / 596;

export const UnstyledLink = styled.a`
    color: inherit;
    text-decoration: none;
`

export const Page = styled.div`
    position: relative;

    /* For some reason, those are A4 dimensions in PX */
    width: 1170px;
    height: 1655px;

    padding: calc(40px * ${designToPageScale});

    page-break-after: always;

    overflow: none;

    :first-of-type {
        padding-top: calc(32px * ${designToPageScale});
    }
`

export const PageHeader = styled.header`
    margin-bottom: calc(40px * ${designToPageScale});

    text-align: center;
`

export const Logo = styled.img`
    width: calc(169px * ${designToPageScale});
    height: calc(51px * ${designToPageScale});

    filter: brightness(0%);
`

export const Section = styled.section`
    &:not(:last-child) {
        margin-bottom: calc(40px * ${designToPageScale});
    }
`

export const SectionHeader = styled.header`
    font-size: 1.33rem;
    font-weight: bold;

    margin-bottom: calc(3px * ${designToPageScale});
`

export const SectionTitleHeader = styled(SectionHeader)`
    margin-bottom: calc(5px * ${designToPageScale});
    font-weight: normal;
`

export const SectionSubtitle = styled.sub`
    font-size: calc(11px * ${designToPageScale});
`

export const PhotoWithText = styled.div`
`

export const PhotoWithTextImage = styled.img`
    width: 100%;
    height: calc(293px * ${designToPageScale});
    object-fit: cover;
`

export const GoogleMap = styled.div`
    width: calc(454px * ${designToPageScale});
    height: calc(258px * ${designToPageScale});

    * {
        width: 100%;
        height: 100%;

        border: 0;
    }
`

export const PhotoWithTextContent = styled.div`
    font-size: calc(7.5px * ${designToPageScale});
    text-align: center;

    letter-spacing: 0;
`

export const InformationsList = styled.div`
    margin-top: calc(7px * ${designToPageScale});

    display: grid;
    gap: 20px 40px;
    grid-template-columns: 1fr 1fr 1fr;
`

export const InformationsListElement = styled.div``;

export const PageFooter = styled.footer`
    position: absolute;

    bottom: calc(40px * ${designToPageScale});

    width: calc(100% - 40px * ${designToPageScale} * 2);

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: calc(10px * ${designToPageScale});
    font-weight: bold;
`

export const FooterItem = styled.div`
`

export const FooterSplit = styled.div`
    width: calc(17px * ${designToPageScale});
    height: calc(1px * ${designToPageScale});

    background: black;
`

export const TextArea = styled.pre`
    font: inherit;
    white-space: pre-wrap;

    ${props => props.bottomMargin ? `margin-bottom: calc(40px * ${designToPageScale});`: ''}
`