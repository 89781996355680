import React from "react"
import Loading from "../components/Loading"
import OfferPrintPage from "../components/OfferPrintPage"

const OfferPrint = ({ pageContext }) => {
  const { offer: dataValue } = pageContext

  if(!dataValue || !dataValue.id)
    return <div
      style={{
        background: "#030303",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading />
    </div>;

  return (
    <>
      <OfferPrintPage offer={dataValue} />
    </>
  )
}

export default OfferPrint
